import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typing from 'react-typing-animation';
import Button from '@material-ui/core/Button';
import apiManager from '../services/api-manager';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { r } from './Questions';
import Fade from '@material-ui/core/Fade';
import Ghost from 'react-kawaii/lib/es/components/ghost/Ghost';
import guard
    from '../services/guard';

export default class QuestionResult extends React.Component {
    state = {
        typed: false,
        responses: [],
        loading: true
    };

    results = [
        {
            title: <h2>Ouuupppsss...!!</h2>,
            content: (
                <>
                    <p>Vous avez obtenu un score insuffisant...</p>
                    <Typing.Delay ms={1000} />
                    <p>Toutes vos chances de réussir pourraient s'envoler</p>
                    <Typing.Delay ms={1000} />
                    <p>si vous parvenez pas au bout de la prochaine étape !</p>
                </>
            ),
            face: (
                <div className="kaw">
                    <Ghost size={240} mood="sad" color="#E0E4E8" />
                </div>
            )
        },
        {
            title: <h2>Bien joué !</h2>,
            content: (
                <>
                    <p>Votre score est plus que correct</p>
                    <Typing.Delay ms={1000} />
                    <p>Cependant vous n'avez pas excellé...</p>
                    <Typing.Delay ms={1000} />
                    <p>Soyez attentif car la suite ne vous fera pas de cadeaux</p>
                    <Typing.Delay ms={1000} />
                    <p>N'oubliez pas, seuls les meilleurs seront récompensés !</p>
                </>
            ),
            face: (
                <div className="kaw">
                    <Ghost size={240} mood="excited" color="#E0E4E8" />
                </div>
            )
        },
        {
            title: <h2>Excellent travail !</h2>,
            content: (
                <>
                    <p>Votre score dépasse toutes nos attentes</p>
                    <Typing.Delay ms={1000} />
                    <p>Vous êtes sur la bonne voie</p>
                    <Typing.Delay ms={1000} />
                    <p>Je suis sur que les prochaines étapes seront du gateaux pour vous !</p>
                </>
            ),
            face: (
                <div className="kaw">
                    <Ghost size={240} mood="lovestruck" color="#E0E4E8" />
                </div>
            )
        }
    ];

    componentDidMount() {
        apiManager.get('/users/responses')
            .then(response => {
                const responses = guard.isGuest ? JSON.parse(localStorage.getItem('guest_responses') || '[]') : response.data.responses;
                this.setState({ loading: false, responses });
            })
            .catch(() => {
                toast.error('Une erreur est survenue lors de la récupération de vos réponses');
            })
        ;
    }

    render() {
        const { loading, responses, typed } = this.state;

        if (loading) {
            return (
                <Grid container justify="center" direction="column" alignItems="center" className="result-container">
                    <CircularProgress />
                </Grid>
            );
        }

        const result = this.results[getResult(responses)];
        return !result ? '' : (
            <Grid container justify="center" direction="column" alignItems="center" className="result-container" wrap="nowrap">
                <span className="skip" onClick={() => this.setState({ typed: !typed, loading: typed }, () => this.setState({ loading: false }))}>{ typed ? 'Rejouer' : 'Passer' }</span>
                <Fade in timeout={2000}>
                    <Grid container justify="center" direction="column" alignItems="center">
                        { result.face }
                        { result.title }
                    </Grid>
                </Fade>
                { typed ? <div className="typing">{ result.content }</div> : (
                    <Typing
                        startDelay={1000}
                        speed={35}
                        className="typing"
                        cursorClassName="typing-cursor"
                        onFinishedTyping={() => this.setState({ typed: true })}
                    >
                        { result.content }
                    </Typing>
                )}
                <br/>
                <Fade in={typed} timeout={1000}>
                    <Button variant="outlined" onClick={() => this.props.helper.next()}>
                        Continuer
                    </Button>
                </Fade>
            </Grid>
        );
    }
}

function getScore(responses) {
    let score = 0;
    for (let i=0, len = responses.length; i < len; ++i) {
        if (r[i] === responses[i]) ++score;
    }
    return score;
}

export function getResult(responses) {
    const score = getScore(responses);

    if (score < 3) {
        return 0;
    } else if (score < 4) {
        return 1;
    }

    return 2;
}
