import React from 'react';
import guard from '../services/guard';
import apiManager from '../services/api-manager';
import ParticlesBg from 'particles-bg'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import logo from '../assets/img/logo_w.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class Login extends React.Component {
    static public = true;

    state = {
        values: {
            username: '',
            password: ''
        },
        loading: false,
        showPassword: false,
        guest: false
    };

    componentDidMount() {
        guard.isAuthenticate() && this.props.helper.next();
    }

    handleChange = e => {
        this.setState({
            values: {
                ...this.state.values,
                ...{ [e.target.name]: e.target.value }
            }
        })
    };

    handleSubmit = e => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        const { values } = this.state;
        if (!values.username || !values.password) {
            return toast.error('Identifiants invalides');
        }

        this.setState({ loading: true }, () => {
            apiManager.post('/token', values, { public: true })
                .then(({ data }) => {
                    guard.login(data.token, data['refresh_token']);
                    this.props.helper.next();
                }).catch(data => {
                    if (data.response) {
                        if (data.response.status === 401) {
                            toast.error('Identifiants invalides');
                        } else {
                            toast.error(data.response.data.message);
                        }
                    } else {
                        toast.error(data.message);
                    }

                    this.setState({ loading: false });
                })
            ;
        });
    };

    render()  {
        const { values, loading, showPassword, guest } = this.state;

        return (
            <Grid container spacing={0} className="view" justify="center" alignItems="center">
                <div className="center-block">
                    <Dialog
                        open={guest}
                        aria-labelledby="responsive-dialog-title"
                        className="modal"
                    >
                        <DialogTitle id="responsive-dialog-title">Vous aller continuer en tant qu'invité</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Les actions d'un invité sont limitées, les données saisies ne seront pas enregistrées.
                                Si vous souhaitez que l'on conserve vos réponses, contactez-nous pour qu'on vous créer un compte.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={() => this.setState({ guest: false })} color="primary">
                                Annuler
                            </Button>
                            <Button
                                onClick={() => {
                                    this.setState({ guest: false },() => {
                                        this.setState({ values: { username: 'guest', password: 'guest' }}, this.handleSubmit)
                                    });
                                }}
                                color="primary"
                                autoFocus
                            >
                                Continuer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <ParticlesBg type="thick" bg />
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid container justify="center" alignItems="center" direction="column">
                            <img src={logo} alt="Babygender"/>
                            <br/>
                            <Typography variant="subtitle1">
                                Allez-vous découvrir le sexe du bébé ..?
                            </Typography>
                            <hr className="styled"/>
                        </Grid>
                    </Grid>
                    <form onSubmit={this.handleSubmit} autoComplete="off">
                        <Grid container direction="column" justify="space-between" alignItems="center">
                            <Grid item>
                                <TextField
                                    value={values.username}
                                    onChange={this.handleChange}
                                    name="username"
                                    label="Utilisateur"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    name="password"
                                    label="Mot de passe"
                                    type={showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item container justify="center" alignItems="center" direction="column" wrap="nowrap">
                                { loading ? <CircularProgress /> : (
                                    <>
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            size="large"
                                            fullWidth
                                            startIcon={<LockOpenOutlined />}
                                        >Connexion</Button>
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            fullWidth
                                            onClick={() => {
                                                this.setState({ guest: true });
                                            }}
                                        >Sans Connexion</Button>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Grid>
        );
    }
}
