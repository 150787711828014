import React from 'react';
import Typing from 'react-typing-animation';
import Grid from '@material-ui/core/Grid';
import logo from '../assets/img/logo_w.png';
import Fade from '@material-ui/core/Fade';
import ParticlesBg from 'particles-bg';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button';
import apiManager from '../services/api-manager';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import DoneOutlined from '@material-ui/icons/DoneOutlined';

export default class Congrats extends React.Component {
    state = {
        typed: false,
        particle: false,
        name: '',
        loading: true,
        loaded: false
    };

    componentDidMount() {
        apiManager.get('/users/name')
            .then(response => {
                const name = response.data.name;
                this.setState({ loading: false, name: name ?? '', loaded: name !== null && name !== '' });
            })
            .catch(() => {
                toast.error('Une erreur est survenue lors de la récupération de votre proposition');
            })
        ;
    }

    handleSubmit = e => {
        e.preventDefault();

        if (!this.state.name) {
            return;
        }

        this.setState({ submit: true }, () => {
            apiManager.post('/users/name', { name: this.state.name })
                .then(response => {
                    this.setState({ submit: false, name: response.data.name, loaded: true });
                })
                .catch(() => {
                    toast.error('Une erreur est survenue lors de la soumission de votre proposition');
                })
            ;
        });
    };

    render() {
        const { typed, particle, name, loading, loaded, submit } = this.state;

        if (loading) {
            return (
                <Grid container justify="center" direction="column" alignItems="center" className="congrats-container">
                    <CircularProgress />
                </Grid>
            );
        }

        return (
            <Grid
                container
                justify="center"
                direction="column"
                alignItems="center"
                className="congrats-container"
            >
                { particle && (
                    <Fade in timeout={3000}>
                        <ParticlesBg type="thick" bg />
                    </Fade>
                )}
                <div className={`center-block ${typed ? 'in' : ''}`}>
                    <Fade in timeout={2000}>
                        <span>
                            <img src={logo} alt="Babygender" width={200}/>
                        </span>
                    </Fade>
                    <br/>
                    <Typing
                        className="typing"
                        speed={30}
                        startDelay={2000}
                        cursorClassName="typing-cursor"
                        onFinishedTyping={() => {
                            setTimeout(() => {
                                this.setState({ typed: true }, () => {
                                    setTimeout(() => this.setState({ particle: true }), 2000)
                                });
                            }, 1000);
                        }}
                    >
                        <h6>Merci d'avoir été jusqu'au bout</h6>
                        <Typing.Delay ms={1000} />
                    </Typing>
                    { typed && (
                        <Fade in timeout={1500}>
                            <form onSubmit={this.handleSubmit}>
                                <br/><br/>
                                <Grid container justify="center" direction="column" alignItems="center">
                                    <TextField
                                        // className={loaded ? '' : 'bounce'}
                                        disabled={loaded === true && name}
                                        value={name}
                                        onChange={e => this.setState({ name: e.target.value })}
                                        label="Proposer un prénom"
                                        variant="outlined"
                                    />
                                    <br/>
                                    { loaded ? <p className="success"><DoneOutlined /></p> : (
                                        submit ? <p><CircularProgress /></p> : (
                                        <Button type="submit" variant="outlined" size="large" onClick={this.handleResponse}>
                                            Valider
                                        </Button>
                                    ))}
                                    { }
                                </Grid>
                            </form>
                        </Fade>
                    )}
                </div>
            </Grid>
        );
    }
}
