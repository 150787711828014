import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typing from 'react-typing-animation';
import Button from '@material-ui/core/Button/Button';
import Fade from '@material-ui/core/Fade';

export default class Introduction extends React.Component {
    state = {
        typed: false,
    };

    content = (
        <>
            <h6>Cher famille, chers amis,</h6>
            <Typing.Delay ms={1000} />
            <p>Bienvenue sur babygender</p>
            <p>pour découvrir le sexe du futur bébé</p>
            <h6>qu'il va falloir trouver par vous même !</h6>
            <Typing.Delay ms={2500} />
            <p>Vous serez confrontés à plusieurs épreuves :</p>
            <Typing.Delay ms={1000} />
            <p>Jeux de mémoire, énigmes,</p>
            <p>autant d'avantures qui vont semer votre chemin d'embuches !</p>
            <Typing.Delay ms={1000} />
            <h6>Serez-vous à la hauteur pour découvrir la vérité ?</h6>
            <Typing.Delay ms={1000} />
            <p>Éssayez de résoudre le mystère !</p>
            <Typing.Delay ms={1000} />
            <p>Seuls les meilleurs y parviendrons ...</p>
            <Typing.Delay ms={1000} />
        </>
    );

    render() {
        const { typed } = this.state;

        if (!typed) {
            return (
                <Grid
                    container
                    justify="center"
                    direction="column"
                    alignItems="center"
                    className="introduction-container"
                    wrap="nowrap"
                >
                    <span className="skip" onClick={() => this.setState({ typed: true })}>Passer</span>
                    <Typing
                        startDelay={1000}
                        speed={35}
                        className="typing noscroll"
                        cursorClassName="typing-cursor"
                        onFinishedTyping={() => {
                            setTimeout(() => this.setState({ typed: true }), 1000)
                        }}
                    >
                        { this.content }
                    </Typing>
                </Grid>
            );
        }

        return (
            <Grid
                container
                justify="center"
                direction="column"
                alignItems="center"
                className="introduction-container"
                wrap="nowrap"
            >
                <span className="skip" onClick={() => this.setState({ typed: false })}>Rejouer</span>
                <div className="typing">
                    { this.content }
                </div>
                <Fade in timeout={1500}>
                    <Button variant="outlined" onClick={() => this.props.helper.next()}>
                        Continuer
                    </Button>
                </Fade>
            </Grid>
        );
    }
}
