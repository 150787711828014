import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typing from 'react-typing-animation';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import { toast } from 'react-toastify';
import DoneOutlined from '@material-ui/icons/DoneOutlined';

export default class Enigma extends React.Component {
    state = {
        typed: false,
        response: '',
        success: false
    };

    handleResponse = e => {
        e.preventDefault();

        if (this.state.response !== '90') {
            return toast.error('Réponse invalide, désolé !');
        }

        this.setState({ success: true }, () => {
            setTimeout(() => this.props.helper.next(), 1000);
        });
    };

    render() {
        const { typed, response, success } = this.state;

        if (!typed) {
            return (
                <Grid container justify="center" direction="column" alignItems="center" className="enigma-container" wrap="nowrap">
                    <span className="skip" onClick={() => this.setState({ typed: true })}>Passer</span>
                    <Typing
                        startDelay={1000}
                        speed={30}
                        className="typing noscroll"
                        cursorClassName="typing-cursor"
                        onFinishedTyping={() => setTimeout(() => this.setState({ typed: true }), 1500)}
                    >
                        <h6>Une énigme vous barre la route et vous devez la résoudre pour continuer</h6>
                    </Typing>
                </Grid>
            );
        }

        return (
            <Grid container justify="center" direction="column" alignItems="center" className="enigma-container" wrap="nowrap">
                <span className="skip" onClick={() => this.setState({ typed: false })}>Rejouer</span>
                <div className="typing noscroll">
                    <h6>Une énigme vous barre la route et vous devez la résoudre pour continuer</h6>
                </div>
                <br/>
                <Fade in timeout={1500}>
                    <div className="typing enigma">
                        <p>A l'hopital de La pomme d'amour, on fait naitre des bébés tous les jours.</p>
                        <p>Ces enfants sont accouchés par de délicates sages femmes qui aiment leur travail plus que tout au monde.</p>
                        <p>Cet hopital a la chance de faire accoucher 6 bébés par jour en temps normal.</p>
                        <p>Pourtant, cette semaine a été pleine de rebondissements :</p>
                        <br/>
                        <p>- Le mardi, les services ont eu 2 fois plus de naissances que d'habitude.</p>
                        <p>- Le jeudi, les services ont eu 3 fois plus de naissances que la veille.</p>
                        <p>- Le samedi il y a eu 2 fois moins de naissances que le lundi.</p>
                        <p>- Il y a eu autant de naissances le vendredi que le mardi et le mercredi réunis.</p>
                        <p>- Le dimanche, les services ont accouché de double jumeaux, de triple triplets et de quintuple quadruplets en plus de d'habitude.</p>
                        <p>- Le lundi était une journée normale.</p>
                        <p>- Le mercredi il y a eu 2 fois plus de naissances que le samedi.</p>
                        <br/>
                        <p>On recense 12 garçons nés cette semaine.</p>
                        <h2>Combien de filles sont nées cette semaine ?</h2>
                    </div>
                </Fade>
                <Slide in={typed} timeout={800} direction="up">
                    <Grid container justify="center" direction="column" alignItems="center" wrap="nowrap">
                        <hr className="styled"/>
                        <form onSubmit={this.handleResponse}>
                            <Grid container justify="center" direction="column" alignItems="center" className="response" wrap="nowrap">
                                <TextField
                                    id="message-input"
                                    autoComplete="off"
                                    name="response"
                                    label="Votre réponse"
                                    variant="outlined"
                                    size="small"
                                    value={response}
                                    onChange={e => this.setState({ response: e.target.value })}
                                />
                                <br/>
                                { success ? <DoneOutlined /> : (
                                    <Button type="submit" variant="outlined" size="large" onClick={this.handleResponse}>
                                        Valider
                                    </Button>
                                )}
                            </Grid>
                        </form>
                    </Grid>
                </Slide>
            </Grid>
        );
    }
}
