import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typing from 'react-typing-animation';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Confetti from 'react-confetti'
import fireworks from '../services/fireworks';
import Button from '@material-ui/core/Button';
import config from '../config';

export default class Discover extends React.Component {
    spotlight = null;

    spotlightSize = 'transparent 30px, rgba(0, 0, 0, 1) 60px)';

    state = {
        typed: false,
        dark: false,
        discover: false,
        finished: false,
        discoverAnimation: false,
        x: 0,
        y: 0
    };

    componentDidMount() {
        window.addEventListener('mousemove', this.updateSpotlight);
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.updateSpotlight);
    }

    updateSpotlight = e => {
        if (this.state.discover) {
            return;
        }

        this.spotPosition = `
            radial-gradient(circle at ${e.pageX / window.innerWidth * 100}% ${e.pageY / window.innerHeight * 100}%, ${this.spotlightSize}
        `;

        if (this.spotlight) {
            this.spotlight.style.backgroundImage = this.spotPosition;
        }

        if (this.state.dark && e.pageX > 200 && e.pageX < 245 && e.pageY < 605 && e.pageY > 550) {
            this.setState({ discover: true }, () => {
                this.handleDiscover(e.clientX, e.clientY, 50);
                setTimeout(() => {
                    if (this.state.discover) {
                        this.setState({ finished: true });
                    }
                }, 10000);
            });
        }
    };

    setTyped(value) {
        if (value) {
            return this.setState({ typed: true, discover: false }, () => {
                this.spotlight.style.backgroundImage = this.spotPosition;
                setTimeout(() => {
                    this.setState({ dark: true });
                }, 4000);
            })
        }

        this.setState({ dark: false, typed: false, discover: false, discoverAnimation: false, finished: false });
    }

    handleDiscover(x, y, base) {
        this.spotlight.style.backgroundImage = `radial-gradient(circle at ${x}px ${y}px, transparent ${base}px, rgba(0, 0, 0, 1) 60px)`;
        if (base < (window.innerHeight + 1000)) {
            setTimeout(() => {
                this.handleDiscover(x, y, base + 2);
            }, 1);
        } else {
            this.setState({ discoverAnimation: true });
        }
    }

    render() {
        const { typed, dark, discover, discoverAnimation, finished } = this.state;

        if (discoverAnimation) {
            return (
                <Grid container justify="center" direction="column" alignItems="center" className="discover-container animation-done">
                    <div>
                        <Confetti
                            width={window.innerWidth}
                            height={window.innerHeight}
                        />
                        <Fireworks/>
                        <Grid container justify="center" direction="column" alignItems="center">
                            <Slide in timeout={10000} direction="up">
                                <div className={`balloons ${config.sex === 1 ? 'girl' : ''}`}>
                                    <div className="container">
                                        <div className="balloon">
                                            {config.sex === 0 ? (
                                                <>
                                                    <div><span>G</span></div>
                                                    <div><span>A</span></div>
                                                    <div><span>R</span></div>
                                                    <div><span>Ç</span></div>
                                                    <div><span>O</span></div>
                                                    <div><span>N</span></div>
                                                </>
                                            ) : (
                                                <>
                                                    <div><span>F</span></div>
                                                    <div><span>I</span></div>
                                                    <div><span>L</span></div>
                                                    <div><span>L</span></div>
                                                    <div><span>E</span></div>
                                                    <div><span>!</span></div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                            {finished && (
                                <Fade in timeout={3000}>
                                    <Button className="next bounce" variant="outlined" onClick={() => this.props.helper.next()}>
                                        Continuer
                                    </Button>
                                </Fade>
                            )}
                        </Grid>
                    </div>
                </Grid>
            );
        }

        if (!typed) {
            return (
                <Grid
                    container
                    justify="center"
                    direction="column"
                    alignItems="center"
                    className="discover-container unstarted"
                >
                    <span className="skip" onClick={() => this.setTyped(true)}>Passer</span>
                    <Typing
                        className="typing"
                        speed={30}
                        startDelay={1000}
                        cursorClassName="typing-cursor"
                        onFinishedTyping={() => this.setTyped(true)}
                    >
                        <h6>Le sexe du bébé : </h6>
                        <Typing.Delay ms={1000} />
                        <p>C'est ce que vous allez découvrir</p>
                        <Typing.Delay ms={1000} />
                        <p>L'heure de la vérité a sonné !</p>
                        <Typing.Delay ms={1000} />
                        <h6>L'écran va bientôt s'assombrir.....</h6>
                        <Typing.Delay ms={1000} />
                        <p>Éclairez le secret à l'aide de votre souris</p>
                        <Typing.Delay ms={1000} />
                        <p>Comme une lanterne, elle vous servira à mettre de la lumière dans l'obscurité</p>
                        <Typing.Delay ms={1000} />
                        <p>C'est elle qui vous permettra de trouver ce symbole : </p>
                        <Typing.Delay ms={1000} />
                        <p style={{ fontSize: '40px' }}>🤍</p>
                        <Typing.Delay ms={1000} />
                        <p>Vous avez 10 secondes pour le mémoriser</p>
                        <Typing.Delay ms={10000} />
                    </Typing>
                </Grid>
            );
        }

        return (
            <Grid
                container
                justify="center"
                direction="column"
                alignItems="center"
                className={`discover-container ${ dark ? 'dark' : ''} ${ discover ? 'discover' : ''}`}
                onClick={e => this.updateSpotlight(e)}
            >
                <span className="skip" onClick={() => this.setTyped(false)}>Rejouer</span>
                { dark && <span className="a" role="img">🤍</span> }
                <Fade in timeout={3000}>
                    <div ref={el => this.spotlight = el} className="spotlight"/>
                </Fade>
            </Grid>
        );
    }
}

class Fireworks extends React.Component {
    el = null;
    id = null;

    componentDidMount() {
        if (this.el !== null) {
            fireworks.init(this.el);
        }
    }

    componentWillUnmount() {
        if (this.id !== null) {
            fireworks.destroy(this.id);
        }
    }

    render() {
        return (
            <div className="fireworks-container" ref={el => this.el = el} />
        )
    }
}

