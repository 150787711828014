import React from 'react';
import Login from './components/Login';
import Countdown from './components/Countdown';
import Introduction from './components/Introduction';
import Preference from './components/Preference';
import Questions from './components/Questions';
import QuestionsResult from './components/QuestionsResult';
import Enigma from './components/Enigma';
import Guard from './components/Guard';
import Discover from './components/Discover';
import Congrats from './components/Congrats';

export default helper => ([
    {
        slug: '',
        className: 'view login-view',
        children: <Login helper={helper} />
    },
    {
        slug: 'countdown',
        className: 'view countdown-view background-ui',
        children: <Countdown helper={helper} />
    },
    {
        slug: 'intro',
        className: 'view background-ui',
        children: <Introduction helper={helper} />
    },
    {
        slug: 'preference',
        className: 'view background-ui',
        children: <Preference helper={helper} />
    },
    {
        slug: 'questions',
        className: 'view background-ui',
        children: <Questions helper={helper} />
    },
    {
        slug: 'results',
        className: 'view background-ui',
        children: <QuestionsResult helper={helper} />
    },
    {
        slug: 'enigma',
        className: 'view background-ui',
        children: <Enigma helper={helper} />
    },
    {
        slug: 'guard',
        className: 'view background-ui',
        children: <Guard helper={helper} />
    },
    {
        slug: 'discover',
        className: 'view background-ui',
        children: <Discover helper={helper} />
    },
    {
        slug: 'congrats',
        className: 'view background-ui congrats',
        children: <Congrats helper={helper} />
    }
]);
