import React from 'react';
import Typing from 'react-typing-animation';
import Grid from '@material-ui/core/Grid/Grid';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button/Button';
import HighlightOffOutlined from '@material-ui/icons/HighlightOffOutlined';
import DoneOutlined from '@material-ui/icons/DoneOutlined';
import wedding from '../assets/img/wedding.png';
import honeymoon from '../assets/img/honeymoon.png';
import astrology from '../assets/img/astrology.png';
import cake from '../assets/img/cake.png';
import baby from '../assets/img/baby.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import apiManager from '../services/api-manager';
import { toast } from 'react-toastify';
import guard
    from '../services/guard';

export const r = [0, 2, 1, 1, 3];

export default class Questions extends React.Component {
    state = {
        typed: false,
        active: null,
        response: null,
        responses: [],
        validResponse: null,
        responseLoading: false,
        loading: true,
        cards: [
            {
                img: wedding,
                title: 'Mariage party',
                text: `
                    <span>Si un jour est important dans la vie d'un couple, c'est bien le jour du mariage.</span>
                    <br/><br/><br/>
                    <strong>Quelle était la date de notre mariage ?</strong>
                `,
                responses: [
                    {
                        text: '25/06/2018',
                        value: '25/06/2018',
                    },
                    {
                        text: '24/06/2018',
                        value: '24/062018',
                    },
                    {
                        text: '26/06/2017',
                        value: '26/06/2017',
                    },
                    {
                        text: '30/06/2018',
                        value: '30/06/2018',
                    }
                ]
            },
            {
                img: honeymoon,
                title: 'Noces de bonheur',
                text: `
                    <span>Qui dit mariage dit voyage de noce ! Le notre fût merveilleux !</span>
                    <br/><br/>
                    <strong>Quelle était la destination de notre voyage de noce ?</strong>
                `,
                responses: [
                    {
                        text: 'Turquie',
                        value: 'Turquie',
                    },
                    {
                        text: 'Liban',
                        value: 'Liban',
                    },
                    {
                        text: 'Crête',
                        value: 'Crête',
                    },
                    {
                        text: 'France',
                        value: 'France',
                    }
                ]
            },
            {
                img: astrology,
                title: 'Astrologie',
                text: `
                    <span>L'astrologie est la mère de l'astronomie, la fille de la mythologie et la grande soeur de la psychologie.</span>
                    <br/><br/>
                    <strong>Que devrait être le signe astologique du bébé ?</strong>
                `,
                responses: [
                    {
                        text: 'Scorpion',
                        value: 'Scorpion',
                    },
                    {
                        text: 'Balance',
                        value: 'Balance',
                    },
                    {
                        text: 'Sagittaire',
                        value: 'Sagittaire',
                    },
                    {
                        text: 'Gémeaux',
                        value: 'Gémeaux',
                    }
                ]
            },
            {
                img: cake,
                title: 'Majorité et liberté',
                text: `
                    <span>Quel étape que de souffler ses 18 bougies ! La majorité, enfin le droit de voter !</span>
                    <br/><br/>
                    <strong>En quelle année fêterons-nous la majorité de notre enfant ?</strong>
                `,
                responses: [
                    {
                        text: '2036',
                        value: '2036',
                    },
                    {
                        text: '2038',
                        value: '2038',
                    },
                    {
                        text: '2039',
                        value: '2039',
                    },
                    {
                        text: '2040',
                        value: '2040',
                    },
                ]
            },
            {
                img: baby,
                title: 'Baby boom',
                text: `
                    <span>Quand un enfant naît, ce sont un père et une mère qui naissent aussi.</span>
                    <br/><br/><br/>
                    <strong>Selon l'INSEE, combien de bébés sont nés en 2019 en France ?</strong>
                `,
                responses: [
                    {
                        text: '632 000',
                        value: '632 000',
                    },
                    {
                        text: '999 000',
                        value: '999 000',
                    },
                    {
                        text: '479 000',
                        value: '479 000',
                    },
                    {
                        text: '753 000',
                        value: '753 000',
                    }
                ]
            },
        ]
    };

    load(callback) {
        return apiManager.get('/users/responses')
            .then(response => {
                const responses = guard.isGuest ? JSON.parse(localStorage.getItem('guest_responses') || '[]') : response.data.responses;
                this.setState({ loading: false, responses }, callback);
            })
            .catch(() => {
                toast.error('Une erreur est survenue lors de la récupération de vos réponses');
            })
        ;
    }

    setTyped = (value) => {
        if (value) {
            return this.setState({ typed: true, loading: true }, () => {
                this.load(() => {
                    setTimeout(() => {
                        let response, validResponse = null;
                        if (this.state.responses[0] !== undefined) {
                            response = this.state.responses[0];
                            validResponse = r[0];
                        }
                        this.setState({ active: 0, response, validResponse });
                    }, 1500)
                });
            });
        }

        this.setState({ typed: value, active: null });
    };

    valid = () => {
        const { active, response, responses } = this.state;

        if (!Number.isInteger(response) || responses[active] !== undefined) {
            return;
        }

        this.setState({ responseLoading: true }, () => {
            apiManager.post('/users/responses', { response })
                .then(() => {
                    responses.push(response);
                    this.setState({ validResponse: r[active], responses, responseLoading: false });
                })
                .catch(() => {
                    toast.error('Une erreur est survenue dans lors de l\'enregistrement de votre réponse');
                    this.setState({ responseLoading: false });
                })
            ;
        });
    };

    next = () => {
        const next = this.state.active + 1;
        let response, validResponse = null;
        if (this.state.responses[next] !== undefined) {
            response = this.state.responses[next];
            validResponse = r[next];
        }

        this.setState({ validResponse, response, active: next });
    };

    handleResponseClick = i => {
        if (this.state.validResponse === null) {
            this.setState({ response: this.state.response === i ? null : i });
        }
    };

    getButtonClass = i => {
        const { response, validResponse } = this.state;

        if (response === i) {
            if (validResponse !== null && response === validResponse) {
                return 'success';
            }

            if (validResponse !== null && response !== validResponse) {
                return 'error';
            }

            return 'active';
        } else {
            if (validResponse !== null && i === validResponse) {
                return 'success';
            }
        }

        return '';
    };

    render() {
        const { typed, cards, active, response, validResponse, responseLoading, loading } = this.state;
        const { helper } = this.props;

        if (!typed) {
            return (
                <Grid container justify="center" direction="column" alignItems="center" className="introduction-container">
                    <span className="skip" onClick={() => this.setTyped(true)}>Passer</span>
                    <Typing
                        className="typing"
                        speed={30}
                        startDelay={1000}
                        cursorClassName="typing-cursor"
                        onFinishedTyping={() => this.setTyped(true)}
                    >
                        <h6>Bravo !</h6>
                        <Typing.Delay ms={1000} />
                        <p>Vous avez décidez de tenter votre chance</p>
                        <Typing.Delay ms={1000} />
                        <p>Votre aventure démarre maintenant avec quelques questions, basiques...</p>
                        <Typing.Delay ms={1000} />
                        <p>Gare aux erreurs, elles seront impardonnables !</p>
                        <Typing.Delay ms={1000} />
                        <p>Patientez un instant...</p>
                        <Typing.Delay ms={3000} />
                    </Typing>
                </Grid>
            );
        }

        if (loading) {
            return (
                <Grid container justify="center" direction="column" alignItems="center" className="introduction-container">
                    <CircularProgress />
                </Grid>
            );
        }

        return (
            typed && (
                <div className="cards-container">
                    <span className="skip" onClick={() => this.setTyped(false)}>Rejouer</span>
                    <Fade in timeout={3000}>
                        <div>
                            <Slide in timeout={1500}>
                                <div>
                                    <div className="cards">
                                        { cards.map((card, i) => (
                                            <div key={i} className={`card card-${ i + 1 } ${ active === i ? 'active' : '' }`}>
                                                <div className="surground"/>
                                                <img src={card.img} alt={card.title}/>
                                                <h3>{ card.title }</h3>
                                                <div className="card-body" dangerouslySetInnerHTML={{ __html: card.text }}/>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </Fade>
                    { cards.map(({ responses }, i) => ( i === active &&
                        <div className="responses-container" key={i}>
                            <div className="responses-title">Séléctionnez votre réponse :</div>
                            <div className="responses">
                                { responses.map(({ text }, i) => (
                                    <Fade in key={i} timeout={3000}>
                                        <Button
                                            className={`response btn-glow ${ this.getButtonClass(i) }`}
                                            onClick={() => this.handleResponseClick(i)}
                                        >
                                            { text }
                                        </Button>
                                    </Fade>
                                ))}
                            </div>
                            <div className="responses-validate">
                                { response !== null && null === validResponse ? (
                                    <Fade in>
                                        <Button variant="outlined" size="large" onClick={this.valid}>Valider</Button>
                                    </Fade>
                                ) : (
                                    null !== validResponse && (
                                        responseLoading ? <CircularProgress/> : (
                                            <Fade in timeout={3000}>
                                                <span className="responses-result">
                                                    { validResponse === response ? <p className="success"><DoneOutlined/></p> : <p className="error"><HighlightOffOutlined/></p> }
                                                    { active === (cards.length - 1) ? (
                                                        <Button
                                                            variant="outlined"
                                                            size="large"
                                                            onClick={() => {
                                                                localStorage.setItem('guest_responses', JSON.stringify(this.state.responses));
                                                                helper.next();
                                                            }}
                                                        >Voir mon résultat</Button>
                                                    ) : (
                                                        <Button variant="outlined" size="large" onClick={this.next} className="bounce">Suivant</Button>
                                                    )}
                                                </span>
                                            </Fade>
                                        )
                                    )
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )
        );
    }
}
