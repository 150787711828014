import React from 'react';

import AwesomeSlider from 'react-awesome-slider'
import 'react-awesome-slider/dist/styles.css';
import { withNavigationHandlers } from "react-awesome-slider/dist/navigation";
import coreStyles from 'react-awesome-slider/src/core/styles.scss';
import cubeAnimation from 'react-awesome-slider/src/styled/cube-animation/cube-animation.scss';
// import scaleOutAnimation from 'react-awesome-slider/src/styled/scale-out-animation/scale-out-animation.scss';
// import fallAnimation from 'react-awesome-slider/src/styled/fall-animation/fall-animation.scss';
// import foldOutAnimation from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
// import openAnimation from 'react-awesome-slider/src/styled/open-animation/open-animation.scss';

const NavigationSlider = withNavigationHandlers(AwesomeSlider);

export default props => (
    <NavigationSlider
        cssModule={[
            coreStyles,
            cubeAnimation,
            // scaleOutAnimation,
            // fallAnimation,
            // foldOutAnimation,
            // openAnimation
        ]}
        {...props}
    />
);
