import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { toast } from 'react-toastify';
import moment from 'moment';

import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/index.scss';

require('dotenv').config();
require('moment/locale/fr');

moment.locale('fr');
toast.configure();

ReactDOM.render(
    <App />,
    document.getElementById('root')
);
