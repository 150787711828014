import React from 'react';
import apiManager from '../services/api-manager';
import Paper from '@material-ui/core/Paper/Paper';
import guard from '../services/guard';
import SpeechBubble from 'react-kawaii/lib/es/components/speechBubble/SpeechBubble';
import moment from 'moment';
import TextField from '@material-ui/core/TextField/TextField';
import Grid from '@material-ui/core/Grid/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import SendIcon from '@material-ui/icons/SendOutlined';
import { toast } from 'react-toastify';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { Face } from '@material-ui/icons';
import emojiCollection from '../services/emoji';

export default class Chat extends React.Component {
    paper = null;

    state = {
        messages: {},
        message: '',
        count: null,
        loading: false,
        ready: false,
        emoji: false
    };

    refresh = null;

    componentDidMount() {
        this.load();
        this.refresh = setInterval(this.load, 20000);
    }

    componentWillUnmount() {
        clearInterval(this.refresh);
    }

    load = () => {
        apiManager.get('/messages/chat').then(response => {
            const hasChanged = this.state.count !== response.data.total;
            if (hasChanged) {
                this.setState({
                    count: response.data.total,
                    messages: response.data.items,
                    ready: true
                }, () => {
                    this.paper.scrollTop = this.paper.scrollHeight;
                });
            }
        }).catch(() => {
            toast.error('Une erreur est survenue lors de la récupération des messages');
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        if (this.state.message) {
            this.setState({ loading: true }, () => {
                apiManager.post('/messages', { content: this.state.message })
                    .then(() => {
                        this.setState({ loading: false, message: '' }, this.load);
                    })
                ;
            });
        }
    };

    toogleEmojiBar = () => {
        this.setState({ emoji: !this.state.emoji });
    };

    handleEmojiClick = (e, em) => {
        if (this.state.loading) {
            return;
        }

        e.preventDefault();
        this.setState({ message: this.state.message + em }, () => {
            document.getElementById('message-input').focus();
        });
    };

    handleMessageChange = e => {
        this.setState({ message: e.target.value });
    };

    render() {
        const { messages, message, loading, ready, emoji } = this.state;

        return (
            <div className="chat-block">
                <Paper variant="outlined" square className="messages" ref={e => this.paper = e}>
                    { !ready && (
                        <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                            <CircularProgress/>
                        </Grid>
                    )}
                    <ul>
                        { Object.keys(messages).map(date => (
                            <span key={date}>
                                <li key={date} className="date">
                                    <div className="divider">
                                        <span/><span className="line-date">{ moment(date).format('D MMM') }</span><span/>
                                    </div>
                                </li>
                                { messages[date].map(msg => (
                                    <li key={msg.id}>
                                        <BootstrapTooltip
                                            title={moment(msg.createdAt).format('[Le] D MMM [à] HH[h]mm')}
                                            placement={String(msg.user.id) !== String(guard.user.id) ? 'left' : 'right'}
                                            className={String(msg.user.id) === String(guard.user.id) ? 'line-container own' : 'line-container'}
                                        >
                                            <div>
                                                { String(msg.user.id) !== String(guard.user.id) && (
                                                    <SpeechBubble size={30} mood="happy" color="#E0E4E8" />
                                                )}
                                                <div className="line">
                                                    <div className="name">
                                                        <strong>{ msg.user.subname }</strong>
                                                    </div>
                                                    <div className="content">
                                                        { msg.content }
                                                    </div>
                                                </div>
                                                { String(msg.user.id) === String(guard.user.id) && (
                                                    <SpeechBubble size={30} mood="happy" color="#E0E4E8" />
                                                )}
                                            </div>
                                        </BootstrapTooltip>
                                    </li>
                                ))}
                            </span>
                        ))}
                    </ul>
                </Paper>
                <form onSubmit={this.handleSubmit}>
                    <TextField
                        id="message-input"
                        autoComplete="off"
                        name="message"
                        label="Écrire un message..."
                        variant="outlined"
                        value={message}
                        disabled={loading}
                        size="small"
                        onChange={this.handleMessageChange}
                    />
                    <div className="actions">
                        <Button className="emoji-button" onClick={this.toogleEmojiBar}>
                            <Face/>
                        </Button>
                        <Button type="submit" disabled={loading}>
                            { loading ? <CircularProgress size={15}/> : <SendIcon/> }
                        </Button>
                    </div>
                    { emoji && (
                        <div className="emoji-bar">
                            { emojiCollection.map((em, i) => (
                                <span onClick={e => this.handleEmojiClick(e, em)} key={i}>{ em }</span>
                            ))}
                        </div>
                    )}
                </form>
            </div>
        );
    }
}

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}
