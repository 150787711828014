import React from 'react';
import Typing from 'react-typing-animation';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import apiManager from '../services/api-manager';
import { toast } from 'react-toastify';
import DoneOutlined from '@material-ui/icons/DoneOutlined';
import Fade from '@material-ui/core/Fade';

export default class Preference extends React.Component {
    state = {
        typed: false,
        loading: true,
        preference: null
    };

    componentDidMount() {
        return apiManager.get('/users/preference')
            .then(response => {
                if (response.data && response.data.preference !== null) {
                    return this.setState({ loading: false, preference: response.data.preference });
                }

                this.setState({ loading: false });
            })
            .catch(() => {
                toast.error('Une erreur est survenue lors de la récupération de votre préférence');
                return this.setState({ loading: false });
            })
        ;
    }

    handleSubmit(preference) {
        return this.setState({ loading: true }, () => {
            apiManager.post('/users/preference', { preference })
                .then(() => {
                    this.setState({ preference, loading: false });
                })
                .catch(() => {
                    toast.error('Une erreur est survenue lors de l\'enregistrement de votre préférence');
                    return this.setState({ loading: false });
                })
            ;
        });
    }

    render() {
        const { typed, loading, preference } = this.state;

        const content = (
            <>
                <h6>Pour commencer</h6>
                <Typing.Delay ms={1000} />
                <p>Veuillez choisir votre préférence</p>
            </>
        );

        if (!typed) {
            return (
                <Grid
                    container
                    justify="center"
                    direction="column"
                    alignItems="center"
                    className="preference-container"
                >
                    <span className="skip" onClick={() => this.setState({ typed: true })}>Passer</span>
                    <Typing
                        className="typing"
                        speed={30}
                        startDelay={1000}
                        cursorClassName="typing-cursor"
                        onFinishedTyping={() => this.setState({ typed: true })}
                    >
                        { content }
                    </Typing>
                </Grid>
            );
        }

        return (
            <Grid
                container
                justify="center"
                direction="column"
                alignItems="center"
                wrap="nowrap"
                className="preference-container"
            >
                <span className="skip" onClick={() => this.setState({ typed: false })}>Rejouer</span>
                <div className="typing">{ content }</div>
                <Fade in timeout={1500}>
                    <div className="buttons">
                        <Button
                            disabled={loading || preference !== null}
                            variant={preference === 0 ? 'contained' : 'outlined'}
                            primary="secondary"
                            onClick={() => this.handleSubmit(0)}
                        >Garçon</Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                            disabled={loading || preference !== null}
                            variant={preference === 1 ? 'contained' : 'outlined'}
                            color={preference === 1 ? 'primary' : undefined}
                            onClick={() => this.handleSubmit(1)}
                        >Fille</Button>
                    </div>
                </Fade>
                { loading && <CircularProgress /> }
                { preference !== null && (
                    <Fade in timeout={1500}>
                        <div className="done">
                            <p className="success">{ loading ? <CircularProgress /> : <DoneOutlined/> }</p>
                            <Button variant="outlined" onClick={() => this.props.helper.next()}>
                                Continuer
                            </Button>
                        </div>
                    </Fade>
                )}
            </Grid>
        );
    }
}
