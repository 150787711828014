import axios from 'axios';
import guard from './guard';
import config from '../config';
import { toast } from 'react-toastify';

export default {
    goto(path) {
        window.location.href = path;
    },
    call(params) {
        return this.checkToken(params.public).then(() => {
            const headers = params.public ? {} : { Authorization: 'bearer ' + (guard.token ?? '') };
            const url = config.api + params.path;
            return axios({ ...params, url, headers });
        });
    },

    post(path, data, params) {
        return this.call({ ...params, method: 'POST', data, path });
    },

    put(path, data, params) {
        return this.call({ ...params, method: 'PUT', data, path });
    },

    get(path, params, config) {
        return this.call({ ...config, params, method: 'GET', path });
    },

    delete(path, params) {
        return this.call({ ...params, method: 'DELETE', path });
    },

    checkToken(isPublic) {
        if (!isPublic && guard.refreshToken && guard.isExpired()) {
            return axios({
                url: config.api + '/token/refresh',
                method: 'POST',
                data: { 'refresh_token': guard.refreshToken }
            }).then(({ data }) => {
                guard.login(data.token, data['refresh_token']);
            }).catch(() => {
                toast.error('Votre jeton de connexion a expiré, veuillez vous reconnecter !');
                this.goto('');
            });
        }

        return Promise.resolve();
    }
}
