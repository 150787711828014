import React from 'react';
import Slider from './Slider';
import routes from '../routes';
import { Provider } from 'react-awesome-slider/dist/navigation';
import guard from '../services/guard';
import apiManager from '../services/api-manager';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/grey';
import Close from '@material-ui/icons/Close';
import Undo from '@material-ui/icons/Undo';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: orange
    },
    typography: {
        "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
});

export default class App extends React.Component {
    routes = [];

    state = {
        active: 0,
        modal: false,
        animation: 'cubeAnimation'
    };


    helper = {
        navigating: false,
        referer: null,
        goto: this.goto.bind(this),
        next: this.next.bind(this),
        prev: this.prev.bind(this),
        get active() {
            return !this.state ? {} : {
                key: this.state.active,
                route: this.routes[this.state.active]
            };
        },
        redirectAnonymous() {
            if (!guard.isAuthenticate()) {
                this.referer = this.active.key;
                this.goto(0);
            }
        }
    };

    constructor(props) {
        super(props);

        this.routes = routes(this.helper);
        this.state.active = this.getRouteIndex(window.location.pathname.substr(1));

        apiManager.goto = this.goto.bind(this);

        if (!guard.isAuthenticate()) {
            this.state.active = 0;
            window.history.pushState(null, '', '/');
        }
    }

    goto(active, animation) {
        if (Number.isInteger(active)) {
            if (active > (this.routes.length - 1)) {
                active = this.routes.length - 1;
            } else if (active < 0) {
                active = 0;
            }
        } else {
            active = this.getRouteIndex(active);
        }

        if (this.routes[active] === undefined) {
            active = 1;
        }

        if (!this.helper.navigating) {
            this.helper.navigating = true;
            this.navigate(active, animation);
            setTimeout(() => this.helper.navigating = false, 1300);
        }
    }

    navigate(active, animation) {
        animation = (animation || 'cube') + 'Animation';
        this.setState({ animation }, () => {
            this.setState({ active }, () => {
                window.history.pushState(null, '', this.routes[active].slug || '/');
            });
        });
    }

    next(animation) {
        this.goto(this.state.active + 1, animation);
    }

    prev(animation) {
        this.goto(this.state.active - 1, animation);
    }

    getRouteIndex(slug) {
        for (let i=0, length = this.routes.length; i < length; ++i) {
            if (slug === this.routes[i].slug) {
                return i;
            }
        }
    }

    render() {
        const { active, modal } = this.state;

        return (
            <ThemeProvider theme={theme}>
                <Provider slug="">
                    <Dialog
                        open={modal}
                        aria-labelledby="responsive-dialog-title"
                        className="modal"
                    >
                        <DialogTitle id="responsive-dialog-title">Deconnexion</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <p>Vous allez être déconnecté et redirigé vers la page d'accueil.<br/>Êtes-vous sûr ?</p>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={() => this.setState({ modal: false })} color="primary">
                                Annuler
                            </Button>
                            <Button
                                onClick={() => {
                                    this.setState({ modal: false }, () => {
                                        guard.logout();
                                        this.navigate(0);
                                    });
                                }}
                                color="primary"
                                autoFocus
                            >
                                Déconnexion
                            </Button>
                        </DialogActions>
                    </Dialog>
                    { active !== 0 && <span className="logout" onClick={() => this.setState({ modal: true })}><Close/></span> }
                    { active > 1 && <span className="back" onClick={() => this.prev()}><Undo/></span> }
                    <Slider
                        animation={this.state.animation}
                        buttons={false}
                        selected={this.state.active}
                        media={this.routes}
                        mobileTouch={false}
                        organicArrows={false}
                        bullets={false}
                    />
                    <span className="brand-bottom">Avec &#9829; par <a target="_blank" rel="noopener noreferrer" href="mailto:admin@babygender.fr" className="mailto">Brandon M.</a></span>
                </Provider>
            </ThemeProvider>
        );
    }
}
