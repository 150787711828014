const dev = {
    api: 'http://localhost:8000',
    sex: 1,
    date: '2020-05-12T19:30:00'
};

const prod = {
    api: 'https://api.babygender.fr',
    sex: 1,
    date: '2020-05-12T19:30:00'
};

const config = process.env.NODE_ENV === 'development' ? dev : prod;

export default config;
