import jwtDecode from 'jwt-decode';

export default {
    get user() {
        return localStorage.getItem('user') !== null && JSON.parse(localStorage.getItem('user'));
    },

    get token() {
        return localStorage.getItem('token');
    },

    get refreshToken() {
        return localStorage.getItem('refresh_token');
    },

    get exp() {
        return localStorage.getItem('exp');
    },

    get isGuest() {
        return Boolean(localStorage.getItem('guest'));
    },

    isAuthenticate() {
        return this.token && this.user && !this.isExpired();
    },

    isExpired() {
        return this.exp && Date.now() > (this.exp * 1000);
    },

    login(token, refreshToken) {
        const decoded = jwtDecode(token);
        token && localStorage.setItem('token', token);
        decoded.username === 'guest' && localStorage.setItem('guest', true);
        refreshToken && localStorage.setItem('refresh_token', refreshToken);
        decoded && decoded.exp && localStorage.setItem('exp', decoded.exp);
        decoded && decoded.user && localStorage.setItem('user', JSON.stringify(decoded.user));
    },

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('guest');
        localStorage.removeItem('guest_responses');
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('decoded_token');
    }
}
