import React from 'react';
import { useState, useRef, useEffect, useLayoutEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Typing from 'react-typing-animation';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade/Fade';
import apiManager from '../services/api-manager';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { getResult } from './QuestionsResult';
import guard
    from '../services/guard';

export default class Guard extends React.Component {
    state = {
        typed: false,
        loading: true,
        responses: []
    };

    content = (
        <>
            <h6>Félicitations !</h6>
            <Typing.Delay ms={1000} />
            <p>Nous savions que vous réussiriez !</p>
            <Typing.Delay ms={1000} />
            <h6>Vous touchez maintenant au but</h6>
            <Typing.Delay ms={1000} />
            <p>Le sexe officiel du bébé se trouve à la prochaine étape</p>
            <Typing.Delay ms={1000} />
            <p>Il suffit de cliquer sur le bouton "suivant"</p>
            <Typing.Delay ms={1000} />
            <p>Mais encore faut-il y arriver...</p>
        </>
    );

    componentDidMount() {
        apiManager.get('/users/responses')
            .then(response => {
                const responses = guard.isGuest ? JSON.parse(localStorage.getItem('guest_responses') || '[]') : response.data.responses;
                this.setState({ loading: false, responses });
            })
            .catch(() => {
                toast.error('Une erreur est survenue lors de la récupération de vos réponses');
            })
        ;
    }

    render() {
        const { typed, loading, responses } = this.state;

        if (loading) {
            return (
                <Grid container justify="center" direction="column" alignItems="center" className="guard-container">
                    <CircularProgress />
                </Grid>
            );
        }

        if (!typed) {
            return (
                <Grid container justify="center" direction="column" alignItems="center" className="guard-container">
                    <span className="skip" onClick={() => this.setState({ typed: true })}>Passer</span>
                    <Typing
                        className="typing"
                        speed={30}
                        startDelay={1000}
                        cursorClassName="typing-cursor"
                        onFinishedTyping={() => this.setState({ typed: true })}
                    >
                        {this.content}
                    </Typing>
                </Grid>
            );
        }

        return (
            <Grid container justify="center" direction="column" alignItems="center" className="guard-container">
                <span className="skip" onClick={() => this.setState({ typed: false })}>Rejouer</span>
                <div className="typing">
                    { this.content }
                </div>
                <br/>
                <Fade in={typed} timeout={1500}>
                    <div className="guard">
                        <App
                            onWin={this.props.helper.next}
                            profile={getResult(responses)}
                        />
                    </div>
                    {/*<Button variant="outlined" size="large" onClick={() => this.props.helper.next()}>*/}
                    {/*    Aller à la prochaine étape*/}
                    {/*</Button>*/}
                </Fade>
            </Grid>
        );
    }
}


// eslint-disable

/**
 * Globals
 */

const CONSTANTS = {
    assetPath: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/184729",
};

const ASSETS = {
    head: `${CONSTANTS.assetPath}/head.svg`,
    waiting: `${CONSTANTS.assetPath}/hand.svg`,
    stalking: `${CONSTANTS.assetPath}/hand-waiting.svg`,
    grabbing: `${CONSTANTS.assetPath}/hand.svg`,
    grabbed: `${CONSTANTS.assetPath}/hand-with-cursor.svg`,
    shaka: `${CONSTANTS.assetPath}/hand-surfs-up.svg`
};

// Preload images
Object.keys(ASSETS).forEach(key => {
    const img = new Image();
    img.src = ASSETS[key];
});


/**
 * Shared hooks
 */

// Hover state - https://dev.to/spaciecat/hover-states-with-react-hooks-4023
const useHover = () => {
    const ref = useRef();
    const [hovered, setHovered] = useState(false);

    const enter = () => setHovered(true);
    const leave = () => setHovered(false);

    useEffect(
        () => {
            ref.current.addEventListener("mouseenter", enter);
            ref.current.addEventListener("mouseleave", leave);
            return () => {
                ref.current.removeEventListener("mouseenter", enter); // eslint-disable-line
                ref.current.removeEventListener("mouseleave", leave); // eslint-disable-line
            };
        },
        [ref]
    );

    return [ref, hovered];
};

// Mouse position
const useMousePosition = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const setFromEvent = e => setPosition({ x: e.clientX, y: e.clientY });
        window.addEventListener("mousemove", setFromEvent);

        return () => {
            window.removeEventListener("mousemove", setFromEvent);
        };
    }, []);

    return position;
};

// Element position
const usePosition = () => {
    const ref = useRef();
    const [position, setPosition] = useState({});

    const handleResize = () => {
        setPosition(ref.current.getBoundingClientRect());
    };

    useLayoutEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [ref.current]); // eslint-disable-line

    return [ref, position];
};


/**
 * React Components
 */

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            cursorGrabbed: false,
            gameOver: false,
            tried: 0
        };

        this.handleToggleDebug = this.handleToggleDebug.bind(this);
        this.handleButtonClicked = this.handleButtonClicked.bind(this);
        this.handleCursorGrabbed = this.handleCursorGrabbed.bind(this);
    }

    handleToggleDebug() {
        this.setState({
            debug: !this.state.debug
        });
    }

    handleCursorGrabbed() {
        this.setState({
            cursorGrabbed: true,
        });

        setTimeout(() => {
            this.setState({
                cursorGrabbed: false
            });
        }, 2000);

        const max = this.maxDepth(this.state.tried + 1);
        if (max) {
            setTimeout(() => {
                return this.setState({ tried: this.state.tried + 1 });
            }, 2500);
        } else {
            return this.setState({ tried: this.state.tried + 1 });
        }
    }

    handleButtonClicked() {
        this.setState({
            gameOver: true
        }, () => {
            setTimeout(() => {
                this.props.onWin && this.props.onWin();
            }, 1000)
        });
    }

    maxDepth(tried) {
        tried = tried ?? this.state.tried;

        switch (this.props.profile) {
            case 2: {
                return tried >= 5
            }
            case 1: {
                return tried >= 7
            }
            default: {
                return tried >= 9
            }
        }
    }

    render() {
        const { cursorGrabbed, gameOver, debug } = this.state;
        const screenStyle = cursorGrabbed ? { cursor: 'none' } : {};
        const appClass = debug ? 'app app--debug' : 'app';

        return (
            <div className={appClass} style={screenStyle}>
                {/*<section className="container">*/}
                {/*    <button*/}
                {/*        className="debug-button"*/}
                {/*        onClick={this.handleToggleDebug}>*/}
                {/*        Debug*/}
                {/*    </button>*/}
                {/*</section>*/}

                <Button className="trap-button bounce" variant="outlined" size="large" onClick={this.handleButtonClicked}>
                    { gameOver && 'Ah, enfin !!' }
                    { cursorGrabbed && 'Essaie encore...' }
                    { !gameOver && !cursorGrabbed && 'Suivant'}
                </Button>
                {!this.maxDepth() && (
                    <div className="grab-zone-wrapper">
                        <GrabZone
                            onCursorGrabbed={this.handleCursorGrabbed}
                            cursorGrabbed={cursorGrabbed}
                            gameOver={gameOver}
                        />
                    </div>
                )}
            </div>
        );
    }
}

// GrabZone (The hover trigger zone)
const GrabZone = ({ cursorGrabbed, gameOver, onCursorGrabbed, hide }) => {
    const [outerRef, outerHovered] = useHover();
    const [innerRef, innerHovered] = useHover();
    const [isExtended, setExtendedArm] = useState(false);

    let state = "waiting";
    if (outerHovered) {
        state = "stalking";
    }
    if (innerHovered) {
        state = "grabbing";
    }
    if (cursorGrabbed) {
        state = "grabbed";
    }
    if (gameOver) {
        state = "shaka"
    }

    // If state is grabbing for a long time, they're being clever!
    useEffect(() => {
            let timer;
            if (state === "grabbing") {
                timer = setTimeout(() => {
                    // Not so clever now, are they?
                    setExtendedArm(true);
                    timer = null;
                }, 2000);
            }
            return () => {
                setExtendedArm(false);
                if (timer) {
                    clearTimeout(timer);
                }
            };
        },
        [state]
    );

    return !hide && (
        <div className="grab-zone" ref={outerRef}>
            {/*<div className="grab-zone__debug">*/}
            {/*    <strong>Debug info:</strong>*/}
            {/*    <p>Current state: {state}</p>*/}
            {/*    <p>Extended arm: {isExtended ? "Yes" : "No"}</p>*/}
            {/*</div>*/}
            <div className="grab-zone__danger" ref={innerRef}>
                <Grabber
                    state={state}
                    gameOver={gameOver}
                    extended={isExtended}
                    onCursorGrabbed={onCursorGrabbed}
                />
            </div>
        </div>
    );
};

// Grabber (The graphic)
const Grabber = ({ state, gameOver, extended, onCursorGrabbed }) => {
    const mousePos = useMousePosition();
    const [ref, position] = usePosition();

    // Calculate rotation of armWrapper
    const x = position.left + position.width * 0.5;
    const y = position.top + position.height * 0.5;
    const angle = gameOver ? 0 : Math.atan2(mousePos.x - x, -(mousePos.y - y)) * (180 / Math.PI);

    // Ensure value is within acceptable range (-75 to 75)
    const rotation = Math.min(Math.max(parseInt(angle), -79), 79);

    const grabberClass = `grabber grabber--${state} ${extended && "grabber--extended"}`;
    const wrapperStyle = { transform: `rotate(${rotation}deg)` };

    let handImageSrc = ASSETS[state];

    return (
        <div className={grabberClass}>
            <div className="grabber__body"/>
            <img className="grabber__face" src={ASSETS.head} alt="grabber-face" />
            <div className="grabber__arm-wrapper" ref={ref} style={wrapperStyle}>
                <div className="grabber__arm">
                    <img
                        className="grabber__hand"
                        src={handImageSrc}
                        onMouseEnter={onCursorGrabbed}
                        alt="grabber-hand"
                    />
                </div>
            </div>
        </div>
    );
};
// eslint-enable
