import React from 'react';
import Countdown from 'react-countdown-now';
import Grid from '@material-ui/core/Grid';
import Chat from './Chat';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import ButtonBase from '@material-ui/core/ButtonBase/ButtonBase';
import Zoom from '@material-ui/core/Zoom';
import config from '../config';

export default class CountDown extends React.Component {
    getNumber(value, last) {
        value = String(value);
        if (value.length === 1) {
            return last ? value : '0';
        }
        return last ? String(value)[1] : String(value)[0];
    }

    render() {
        const { helper } = this.props;

        return (
            <Grid container justify="center" direction="column" alignItems="center" className="countdown-container">
                <div className="title-block">
                    <div className="title">Babygender</div>
                    <div className="subtitle">N'hésitez pas à nous laisser un message sur ce chat public !</div>
                </div>
                <Chat />
                <Countdown
                    date={new Date(config.date)}
                    renderer={({ hours, minutes, seconds }) => (
                        <div className="container">
                            {hours === 0 && minutes === 0 && seconds === 0 ? (
                                <div className="num-block btn">
                                    <Zoom in timeout={1000}>
                                        <ButtonBase className="num bounce" onClick={() => helper.next()}>
                                            <PlayCircleOutline style={{ fontSize: 40 }}/> Commencer
                                        </ButtonBase>
                                    </Zoom>
                                    <span className="subtitle">&nbsp;</span>
                                </div>
                            ) : (
                                <>
                                    <div className={`num-block ${!hours ? 'disabled' : ''}`}>
                                        <Grid container justify="center" direction="row" alignItems="center">
                                            <span className="num">{ this.getNumber(hours) }</span>
                                            <span className="num">{ this.getNumber(hours, true) }</span>
                                        </Grid>
                                        <span className="subtitle">Heures</span>
                                    </div>
                                    <div className={`num-block ${!minutes && !hours ? 'disabled' : ''}`}>
                                        <Grid container justify="center" direction="row" alignItems="center">
                                            <span className="num">{ this.getNumber(minutes) }</span>
                                            <span className="num">{ this.getNumber(minutes, true) }</span>
                                        </Grid>
                                        <span className="subtitle">Minutes</span>
                                    </div>
                                    <div className={`num-block ${!seconds && !minutes && !hours ? 'disabled' : ''}`}>
                                        <Grid container justify="center" direction="row" alignItems="center">
                                            <span className="num">{ this.getNumber(seconds) }</span>
                                            <span className="num">{ this.getNumber(seconds, true) }</span>
                                        </Grid>
                                        <span className="subtitle">Secondes</span>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                />
                <div />
            </Grid>
        );
    }
}
